import axios from 'axios';

const webSiteUrl = 'https://api.yatirimatlas.net';

const fetchLogin = async (identifier, password) => {
  try {
    const response = await axios.post(`${webSiteUrl}/api/users/login`, {
      identifier,
      password
    }, {
      headers: { 'Content-Type': 'application/json' }
    });
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw new Error('Sistem Hatası! Lütfen daha sonra tekrar deneyiniz.');
    } else {
      const errorMessage = error.response.data?.message || error.response.data?.error || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.';
      throw new Error(errorMessage);
    }
  }
};



const fetchLogout = async (authToken) => {
  try {
    const response = await axios.post(`${webSiteUrl}/api/users/logout`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during logout:', error);
    if (error.response) {
      throw new Error(error.response.data.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
    } else {
      throw new Error('Error during logout');
    }
  }
};



export const fetchForgotPassword = async (email) => {
  try {
    const response = await axios.post(`${webSiteUrl}/api/users/forgot-password`, { email }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during forgot password request:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};

export const fetchResetPassword = async (token, password) => {
  try {
    const response = await axios.post(`${webSiteUrl}/api/users/reset-password/${token}`, { password }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during password reset:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};


const fetchRegister = async (data) => {
  try {
    const response = await axios.post(`${webSiteUrl}/api/users/register`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during user registration:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};


const fetchUserProfile = async (token) => {
  try {
    const response = await axios.get(`${webSiteUrl}/api/users/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};

const fetchUpdateProfile = async (formData, token) => {
  try {
    const url = `${webSiteUrl}/api/users/profile`;
    const response = await axios.put(url, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};

const fetchSymbols = async (token) => {
  try {
    const response = await axios.get(`${webSiteUrl}/api/margin/infos`, {
		
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.data;
  } catch (error) {
    console.error('Error fetching Symbol Data:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};




const fetchGetSymbol = async (symbol, token) => {
  try {
    const response = await axios.get(`${webSiteUrl}/api/margin/infos`, {
      params: { symbol: symbol },
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data.data[0];
  } catch (error) {
    console.error('Error fetching symbol data:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};



const fetchPositions = async (token, page = 1) => {
  try {
    const response = await axios.get(`${webSiteUrl}/api/orders?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching positions:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};

const fetchWaitingOrders = async (token, page = 1) => {
  try {
    const response = await axios.get(`${webSiteUrl}/api/orders/open?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching waiting orders:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};

const fetchClosedOrders = async (token, page = 1) => {
  try {
    const response = await axios.get(`${webSiteUrl}/api/orders/transaction?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching closed orders:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};

const fetchTraders = async (token) => {
  try {
    const response = await axios.get(`${webSiteUrl}/api/traders`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching traders:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};

const fetchTraderPositionData = async (traderId, token) => {
  try {
    const url = `${webSiteUrl}/api/traders/positions/${traderId}`;
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching trader positions:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};


const fetchTraderHistoryData = async (traderId, token) => {
  try {

    const url = `${webSiteUrl}/api/traders/orders/${traderId}`;
    const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}`},
  });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching trader orders:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};


const fetchTraderCommentsData = async (traderId, token, page = 1) => {
  try {
    const url = `${webSiteUrl}/api/traders/comments/${traderId}?page=${page}`;
	console.log('traderId', traderId)
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
	console.log(response)
    return {
      comments: response.data.data,
      totalPages: response.data.totalPages,
      currentPage: response.data.currentPage,
    };
  } catch (error) {
    console.error('Error fetching trader comments:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};

const fetchFollowTrader = async (traderId, size, token) => {
  try {
	 const sizee = parseFloat(size);
    const response = await axios.post(`${webSiteUrl}/api/traders/follow`, {
      id: traderId,
      size: sizee,
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching trader comments:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};


const fetchTraderUnfollow = async (traderId, token) => {
  try {
    const url = `${webSiteUrl}/api/traders/unfollow/${traderId}`;
    const response = await axios.post(url, {}, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching trader comments:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};

const fetchRobots = async (token) => {
  try {
    const response = await axios.get(`${webSiteUrl}/api/robots/all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching robots:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};

const fetchFollowRobot = async (robotId, size, token) => {
  try {
	 const sizee = parseFloat(size);
    const response = await axios.post(`${webSiteUrl}/api/robots/add`, {
      robotId: robotId,
      positionSize: sizee,
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching trader comments:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};

const fetchFollowDetailRobot = async (robotId, token) => {
  try {
    const response = await axios.post(`${webSiteUrl}/api/robots/detail`, {
      robotId: robotId,
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching trader comments:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};


const fetchEditFollowRobot = async (robotId, size, token) => {
  try {
	 const sizee = parseFloat(size);
    const response = await axios.put(`${webSiteUrl}/api/robots/edit`, {
      robotId: robotId,
      positionSize: sizee,
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching trader comments:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};


const fetchRobotUnfollow = async (robotId, token) => {
  try {
    const url = `${webSiteUrl}/api/robots/delete`;
    const response = await axios.delete(url, {
      data: { robotId: robotId },
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching trader comments:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};


const fetchUploadVerify = async (formData, token) => {
  try {
    const url = `${webSiteUrl}/api/users/upload`; 
    const response = await axios.post(url, formData, {
      headers: { 
        Authorization: `Bearer ${token}`
      }
    });
    return response.data; 
  } catch (error) {
    console.error('Error uploading file:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};

const fetchCompanyAccounts = async (token) => {
  try {
    const response = await axios.get(`${webSiteUrl}/api/infos/accounts`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching traders:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};


const fetchGetFinances = async (token) => {
  try {
    const response = await axios.get(`${webSiteUrl}/api/trans/all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching post deposit:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};


const fetchUserFinancePost = async (formData, token) => {
  try {
    const response = await axios.post(`${webSiteUrl}/api/trans`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.';
    throw new Error(errorMessage);
  }
};


const fetchFinanceList = async (token) => {
  try {
    const response = await axios.get(`${webSiteUrl}/api/infos/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching finance list:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};



// ORDER

const fetchCreateOrder = async (formData, token) => {
  try {
    const response = await axios.post(`${webSiteUrl}/api/orders`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching create order:', error.data.data);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};


const fetchEditOrder = async (orderId, formData, token) => {
  try {
    const url = `${webSiteUrl}/api/orders/update/${orderId}`;
    const response = await axios.put(url, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating the order:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};


const fetchCloseOrder = async (orderId, token) => {
  try {
    const response = await axios.put(`${webSiteUrl}/api/orders/close`, {
      id: orderId,
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Close order:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};

const fetchPartialOrder = async (orderId, size, token) => {
  try {
	const sizee = parseFloat(size);
    const response = await axios.put(`${webSiteUrl}/api/orders`, {
      orderId: orderId,
      pieced: sizee,
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Partial Order:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};

const fetchCancelOrder = async (orderId, token) => {
  try {
    const response = await axios.post(`${webSiteUrl}/api/orders/cancel`, {
      orderId: orderId,
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Cancel Order:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};

const fetchSettings = async () => {
  try {
    const response = await axios.get(`${webSiteUrl}/api/infos/appInfo`);
    return response;
  } catch (error) {
    console.error('Error fetching settings:', error);
    throw new Error(error.response?.data?.message || 'Bir Hata Oluştu, Daha Sonra Deneyiniz.');
  }
};





export { fetchLogin, fetchLogout, fetchRegister, fetchUserProfile, fetchUpdateProfile, fetchSymbols, fetchGetSymbol, fetchPositions, fetchWaitingOrders, fetchClosedOrders, fetchTraders, fetchTraderPositionData,
fetchTraderHistoryData, fetchTraderCommentsData, fetchFollowTrader, fetchTraderUnfollow, fetchRobots, fetchFollowRobot, fetchFollowDetailRobot, fetchEditFollowRobot, fetchRobotUnfollow,
fetchUploadVerify, fetchCompanyAccounts, fetchGetFinances, fetchUserFinancePost, fetchFinanceList, fetchCreateOrder, fetchEditOrder, fetchCloseOrder, fetchPartialOrder, fetchCancelOrder,
fetchSettings};
