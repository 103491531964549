import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '@contexts/AuthContext';
import { fetchEditOrder  } from '@services/apiService';
import {useThemeProvider} from '@contexts/themeContext';
import { ordersContext } from '@contexts/ordersContext';
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify';

function LabelFieldCheckbox({ orderId, stopPrice, takePrice, livePrice, tab, stopActive, takeActive}) {
	const {theme} = useThemeProvider();
	const { authToken } = useAuth();
	const [isStopLossActive, setIsStopLossActive] = useState(stopActive);
	const [isTakeProfitActive, setIsTakeProfitActive] = useState(takeActive);
	const [stopLossAmount, setStopLossAmount] = useState(stopPrice);
	const [takeProfitAmount, setTakeProfitAmount] = useState(takePrice);
    const { refreshOrders } = useContext(ordersContext);
	const navigate = useNavigate();
	
	useEffect(() => {
		if (!isStopLossActive) {
		  setStopLossAmount(stopPrice);
		}
		if (!isTakeProfitActive) {
		  setTakeProfitAmount(takePrice);
		}
	}, [stopPrice, takePrice, isStopLossActive, isTakeProfitActive]);

	useEffect(() => {
		setIsStopLossActive(stopActive);
		setIsTakeProfitActive(takeActive);
	}, [tab]);
  

	const calculateStep = (value) => {
		const valueStr = value.toString();
		if (valueStr.includes('.')) {
			const parts = valueStr.split('.');
			const integerPart = parts[0];
			const decimalPart = parts[1];
			if (decimalPart === '0' || decimalPart === undefined) {
				return 1;
			} else {
				return integerPart.length === 4 ? 0.1 : 0.01;
			}
		}
		return 1;
	};
	
	const step = calculateStep(livePrice);

	const increaseStopLossAmount = () => {
		setStopLossAmount(prevAmount => {
			const newValue = parseFloat(prevAmount) + step;
			if(step === 1){
				return (newValue);
			} else {
				return (newValue).toFixed(2);
			}
		});
	};

	const decreaseStopLossAmount = () => {
		setStopLossAmount(prevAmount => {
			const newValue = parseFloat(prevAmount) - step;
			if(step === 1){
				return (newValue);
			} else {
				return (newValue).toFixed(2);
			}
		});
	};

	const increaseTakeProfitAmount = () => {
		setTakeProfitAmount(prevAmount => {
			const newValue = parseFloat(prevAmount) + step;
			if(step === 1){
				return (newValue);
			} else {
				return (newValue).toFixed(2);
			}
		});
	};

	const decreaseTakeProfitAmount = () => {
		setTakeProfitAmount(prevAmount => {
			const newValue = parseFloat(prevAmount) - step;
			if(step === 1){
				return (newValue);
			} else {
				return (newValue).toFixed(2);
			}
		});
	};


	const handleEditOrder = async () => {
		try {
			const formData = {
				stop: isStopLossActive ? stopLossAmount : null,
				take: isTakeProfitActive ? takeProfitAmount : null,
			};
			const data = await fetchEditOrder(orderId, formData, authToken);	
		
			if(data.status === true){
				await refreshOrders('position');
				navigate('/transactions');
				toast.success('Güncelleme Başarılı', {
					autoClose: 3000
				});
			} else if (data.status === false){ 
				toast.warning(data.message, {autoClose: 5000});
			}
			
		} catch (error) {
			console.error('Error editing the order:', error);
		}
	};


  return (
    <>
      <div className="input-group-trade" style={{ margin: '10px', display: 'flex', alignItems: 'center' }}>
        <label className="checkbox-container">
		<input
          type="checkbox"
          className="checkboxLarge stopLoss checkboxMarket toggleCheckbox"
          style={{ cursor: 'pointer' }}
            checked={isStopLossActive}
            onChange={e => setIsStopLossActive(e.target.checked)}
		  className="field-input marketLimitInput" 
        />
		<span className="checkmark"></span>
		</label>
        <span
          className="input-group-text-trade stopLoss togglediv"
          style={{ cursor: 'pointer', padding: '7px 5px', marginTop: '10px', minWidth: '30%', fontSize: '13px' }}>
          Zarar Durdur
        </span>
        {isStopLossActive && (
          <span className="currencyAmount">
            <div className="input-group-trade" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <button className="btn" onClick={decreaseStopLossAmount} style={{ width: '35px', height: '35px' }}>-</button>
<input
  type="text"
  id="stopLoss"
  value={stopLossAmount}
  onChange={(e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9.]/g, '');
    const parts = value.split('.');
    if (parts.length > 1) {
      value = parts[0] + '.' + parts.slice(1).join('');
    }
    if (value.startsWith('.')) {
      value = '0' + value;
    }
    setStopLossAmount(value);
  }}
  onBlur={() => {
    setStopLossAmount((prev) => prev.endsWith('.') ? prev.slice(0, -1) : prev);
  }}
  className="field-input stopLossInput"
  style={{ height: '35px', textAlign: 'center', maxWidth: '50%' }}
/> 
			  
			  <button className="btn" onClick={increaseStopLossAmount} style={{ width: '35px', height: '35px' }}>+</button>
            </div>
          </span>
        )}
      </div>
	  
      <div className="input-group-trade" style={{ margin: '10px', display: 'flex', alignItems: 'center' }}>
        <label className="checkbox-container">
		<input
          type="checkbox"
          className="checkboxLarge takeProfit checkboxMarket toggleCheckbox"
          style={{ cursor: 'pointer' }}
            checked={isTakeProfitActive}
            onChange={e => setIsTakeProfitActive(e.target.checked)}
        />
		<span className="checkmark"></span>
		</label>
        <span
          className="input-group-text-trade takeProfit togglediv"
          style={{ cursor: 'pointer', padding: '7px 5px', marginTop: '10px', minWidth: '30%', fontSize: '13px' }}
        >
          Kar Al
        </span>
        {isTakeProfitActive && ( 
          <span className="currencyAmount">
            <div className="input-group-trade" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <button className="btn" onClick={decreaseTakeProfitAmount} style={{ width: '35px', height: '35px' }}>-</button>
<input
  type="text"
  id="takeProfit"
  value={takeProfitAmount}
  onChange={(e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9.]/g, '');
    const parts = value.split('.');
    if (parts.length > 1) {
      value = parts[0] + '.' + parts.slice(1).join('');
    }
    setTakeProfitAmount(value);
  }}
  className="field-input takeProfitInput"
  style={{ height: '35px', textAlign: 'center', maxWidth: '50%' }}
/>
			  
			  
			  <button className="btn" onClick={increaseTakeProfitAmount} style={{ width: '35px', height: '35px' }}>+</button>
            </div>
          </span>
        )}
      </div>
		<div className="d-flex flex-column g-8" style={{paddingTop: '0px 10px 0px 10px'}}>
			<button className={` btn ${theme === 'dark' ? 'btn--invert' : ''}`} onClick={handleEditOrder}>
				Pozisyonu Düzenle
			</button>
		</div>
    </>
  );
}

export default LabelFieldCheckbox;
